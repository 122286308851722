import React, { PureComponent } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Card } from 'semantic-ui-react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';

import { flexVert } from '../../styles';
import { DataStructuresVerticalMenu } from '../../components/verticalmenu';

const BENCHMARK_CARDS: BenchmarkCardProps[] = [
    {
        header: 'deepmesa::LinkedList vs std::LinkedList',
        description:
            'The deepmesa LinkedList is 2x faster than the std::LinkedList. It also allows constant time inserts & removes in the middle of the list with a 35% better performance. ',
        meta: '2x faster than std::LinkedList',
        link: "/benchmarks/linkedlist"
    }
]

interface BenchmarkCardProps {
    header: string,
    description: string,
    meta: string,
    link: string,
}

const BenchmarkCard = (props: BenchmarkCardProps) => {
    return (
        <Card style={{ width: '400px' }}>
            <Card.Content>
                <Card.Header><Link to={props.link}>{props.header}</Link></Card.Header>
                <Card.Meta>{props.meta}</Card.Meta>
                <Card.Description>
                    {props.description}
                </Card.Description>
            </Card.Content>
        </Card >
    );
}


const BenchmarksPage = () => {
    return (
        <Layout>
            <Card.Group>
                {BENCHMARK_CARDS.map((prop: BenchmarkCardProps) => (
                    <BenchmarkCard {...prop} />
                ))}
            </Card.Group>
        </Layout >
    );
}

export default BenchmarksPage;
